import { ElectionType, electionTypeMap } from '../api/api-types';
import { useRouter } from '../application/Router';

// Valid values for the election query param
const lookupMap = {
  0: ElectionType.Folketingsvalg,
  dk: ElectionType.Folketingsvalg,
  1: ElectionType.EuropaParlamentsvalg,
  eu: ElectionType.EuropaParlamentsvalg,
};

/**
 * Get the current electionType from the url search params
 * */
export function useElectionType(): {
  electionType: ElectionType;
  electionTypeValue: string;
  isDefined: true;
};
// eslint-disable-next-line no-redeclare
export function useElectionType(): {
  electionType?: ElectionType;
  electionTypeValue?: string;
  isDefined: boolean;
} {
  const { searchParams } = useRouter();
  if (!searchParams) {
    return {
      electionType: undefined,
      electionTypeValue: undefined,
      isDefined: false,
    };
  }

  // Check for the election type in search params. Check for both `election` and `electionType`.
  const value =
    searchParams.get('election') || searchParams.get('electionType');
  // Check if the value exists, and return the election type that matches
  const key = (value && lookupMap[value.toLowerCase()]) ?? undefined;
  const electionType = key || ElectionType.Folketingsvalg;

  return {
    electionType,
    electionTypeValue: electionTypeMap[electionType],
    isDefined: key !== undefined,
  };
}

/**
 * Get the current party Id from the url search params
 * */
export function usePartyId() {
  const { searchParams } = useRouter();
  if (!searchParams) return { partyId: undefined };

  return { partyId: searchParams.get('party') };
}
