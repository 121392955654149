import {
  CancelledError,
  QueryConfig,
  QueryResult,
  queryCache,
  useQuery,
} from 'react-query';

import { SchemaPartyDetailsResponse } from '../api-schema';
import { IError } from '../api-types';
import { fetcher } from '../fetcher';

const queryKey = 'party';
const staleTime = 1000 * 60 * 5;

async function loadParty(key: string, partyId?: string | null) {
  const url = partyId ? `/api/v1/parties/${partyId}` : null;
  if (!url) {
    const error: IError = new CancelledError();
    error.message = '[404] No partyId provided';
    error.status = 404;
    throw error;
  }

  const result: SchemaPartyDetailsResponse = await fetcher(url);
  if (result.serviceResult === 0) {
    // Invalid parti - Most likely caused by a valid UUID structure, but without a matching parti.
    const error: IError = new CancelledError();
    error.message = '[404] Parti not found';
    error.status = 404;
    throw error;
  }

  return result;
}

export function prefetchParty(
  partyId?: string,
  options?: QueryConfig<SchemaPartyDetailsResponse, IError>,
) {
  if (!partyId) return null;
  return queryCache.prefetchQuery([queryKey, partyId], loadParty, {
    retry: false,
    staleTime,
    ...options,
  });
}

/**
 * Get specific party by id
 * */
export function useParty(
  partyId?: string | null,
  options?: QueryConfig<SchemaPartyDetailsResponse, IError>,
): QueryResult<SchemaPartyDetailsResponse, IError> {
  return useQuery([queryKey, partyId], loadParty, {
    enabled: !!partyId,
    staleTime,
    ...options,
  });
}

useParty.displayName = 'useParty';
