import * as React from 'react';

import { useParty } from '../../api/hooks/useParty';
import { useDictionary } from '../../hooks/useDictionary';
import { useElectionType, usePartyId } from '../../hooks/useSearchParams';
import { DeclarationActionTypes } from '../../view-models/DeclarationActionTypes';
import { MessageStatusTypes } from '../../view-models/MessageStatusTypes';
import Modal from '../Modal/Modal';
import RichText from '../RichText/RichText';
import { CollectionState } from '../../api/api-types';

export function getErrorWithState(state, action, defaultMassage) {
  const stateInteger = parseInt(state);

  switch (action) {
    case DeclarationActionTypes.initiate:
      if (stateInteger === CollectionState.locked) {
        return 'errors.declaration_initiation_election_locked_text';
      }
      if (stateInteger === CollectionState.approved) {
        return 'errors.declaration_initiation_election_approved_text';
      }
      if (stateInteger === CollectionState.deactivated) {
        return 'errors.declaration_initiation_election_deactivated_text';
      }
      if (stateInteger === CollectionState.noCollection) {
        return 'errors.declaration_initiation_election_deleted_text';
      }
      return defaultMassage;
    case DeclarationActionTypes.confirm:
      if (stateInteger === CollectionState.locked) {
        return 'errors.declaration_confirmation_election_locked_text';
      }
      if (stateInteger === CollectionState.approved) {
        return 'errors.declaration_confirmation_election_approved_text';
      }
      if (stateInteger === CollectionState.deactivated) {
        return 'errors.declaration_confirmation_election_deactivated_text';
      }
      if (stateInteger === CollectionState.noCollection) {
        return 'errors.declaration_confirmation_election_deleted_text';
      }
      return defaultMassage;
    case DeclarationActionTypes.withdraw:
      if (stateInteger === CollectionState.locked) {
        return 'errors.declaration_withdrawal_election_locked_text';
      }
      if (stateInteger === CollectionState.approved) {
        return 'errors.declaration_withdrawal_election_approved_text';
      }
      if (stateInteger === CollectionState.deactivated) {
        return 'errors.declaration_withdrawal_election_deactivated_text';
      }
      if (stateInteger === CollectionState.noCollection) {
        return 'errors.declaration_withdrawal_election_deleted_text';
      }
      return defaultMassage;
    default:
      return defaultMassage;
  }
}

type Props = {
  isOpen: boolean;
  onDismiss: () => void;
  status: MessageStatusTypes;
  action?: DeclarationActionTypes | null;
  state?: CollectionState;
};

function StatusMessageOverlay({
  isOpen = true,
  onDismiss,
  action,
  status,
  state,
}: Props) {
  const { t } = useDictionary();
  const { electionTypeValue } = useElectionType();
  const { partyId } = usePartyId();
  const { data, status: loadingStatus } = useParty(partyId, { suspense: true });

  if (loadingStatus === 'loading') return null;

  const interpolation = {
    party: data?.name,
    electionType: t(`${electionTypeValue}Election`),
  };

  let message = {
    titleKey: 'errors.generic_title',
    textKey: 'errors.generic_text',
  };

  switch (action) {
    case DeclarationActionTypes.confirm:
      if (status === MessageStatusTypes.error) {
        message.textKey = getErrorWithState(state, action, message.textKey);
      }
      if (status === MessageStatusTypes.ok) {
        message.titleKey = 'modal.status_14_02_title';
        message.textKey = 'modal.status_14_02_text';
      }
      break;
    case DeclarationActionTypes.withdraw:
      if (status === MessageStatusTypes.error) {
        message.textKey = getErrorWithState(state, action, message.textKey);
      }
      if (status === MessageStatusTypes.ok) {
        message.titleKey = 'modal.status_14_03_title';
        message.textKey = 'modal.status_14_03_text';
      }
      break;
    case undefined:
      if (status === 'error') {
        message.titleKey = 'errors.data_not_initialized_title';
        message.textKey = 'errors.data_not_initialized_text';
      }
      break;
  }

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      title={t(message.titleKey, { ...interpolation, asString: true })}
    >
      <RichText>
        {t(message.textKey, { ...interpolation, asString: true })}
      </RichText>
    </Modal>
  );
}

export default StatusMessageOverlay;
